import { createStore } from 'vuex'
const store = createStore({
  state() {
    return {
      loginState: false,
      anchor: 0,
      ifPC: true
    }
  },
  mutations: {
    ifLoginState(state, status) {
        state.loginState = status
    },
    anchorIndex(state, status) {
      state.anchor = status
    },
    updateIfPC(state, status) {
      state.ifPC = status;
    },
  },
  actions: {
    IF_LOGIN_STATE(context) {
      setTimeout(() => {
        context.commit('increment')
      }, 1000)
    }
  }
})
export default store