<template>
  <a-modal
    :open="visible"
    title="信华信（总部）"
    @cancel="handleOk"
    width="20%"
    class="login_modal"
  >
    <!-- <div class="login_title">用户{{ ifLogin ? "登录" : "注册" }}</div>
    <div class="form">
      <a-form
        :model="form"
        name="basic"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
      >
        <a-row v-if="ifLogin">
          <a-col :span="24">
            <a-form-item label="用户名" name="userName" class="oneItem">
              <a-input
                v-model:value="formState.userName"
                placeholder="用户名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="密码" name="password" class="oneItem">
              <a-input v-model:value="formState.password" placeholder="密码" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="!ifLogin">
          <a-col :span="24">
            <a-form-item label="姓名" name="nation" class="oneItem">
              <a-input v-model:value="formState.surname" placeholder="姓氏" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="电话" name="phone" class="oneItem">
              <a-input v-model:value="formState.phone" placeholder="电话" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="邮箱" name="email" class="oneItem">
              <a-input v-model:value="formState.email" placeholder="电子邮件" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="公司" name="email" class="oneItem">
              <a-input v-model:value="formState.email" placeholder="公司" />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="login_button_content">
          <div class="button_contain">
            <a-button type="primary" class="login_button">{{
              ifLogin ? "快速登录" : "快速注册"
            }}</a-button>
            <div class="alrady_have_account" v-if="ifLogin">
              没有账号？
              <a class="quick_login" @click="singIne">快速注册</a>
            </div>
            <div class="alrady_have_account" v-if="!ifLogin">
              <a class="quick_login" @click="backLogin">返回登录</a>
            </div>
          </div>
        </div>
      </a-form>
    </div> -->
    <div class="login_title">扫码体验</div>
    <img src="@/assets/communityCode.png" class="loginCode"/>
    <div class="login_des">关注二维码获取体验线上体验账号</div>
  </a-modal>
</template>
<script setup name="LoginComponent">
import { defineEmits, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const visible = computed(() => {
  return store.state.loginState;
});
const emits = defineEmits(["close"]);
// const ifLogin = ref(true);
// const formState = reactive({});
// const form = ref({});
// const singIne = () => {
//   ifLogin.value = false;
// };
// const backLogin = () => {
//   ifLogin.value = true;
// };
const handleOk = () => {
  emits("close");
};
</script>
<style scoped lang="less">
.login_modal {
  text-align: center;
}
.login_title {
  font-size: 24px;
  text-align: center;
}
.login_des {
  font-size: 16px;
  padding-bottom: 20px;
  text-align: center;
}
.form {
  padding-top: 30px;
}
.login_button_content {
  text-align: center;
  padding-top: 10px;
}
.alrady_have_account {
  padding-top: 14px;
}
.loginCode {
  width: 100%;
  padding: 20px 20px 0;
}
</style>
