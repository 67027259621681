import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "homeView",
    component: () => import("@/views/homeView"),
  },
  // 公司简介
  {
    path: "/companyIntroduce",
    name: "companyIntroduce",
    component: () => import("@/views/companyIntroduce"),
  },
  // 产品中心
  {
    path: "/ProductCenter",
    name: "ProductCenter",
    component: () => import("@/views/ProductCenter"),
  },
  ];
export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});
router.beforeEach((to, from, next) => {
  $(document).ready(function () {
    if (to.fullPath == "./") {
      $(".footer").css({ display: "none" });
    } else {
      $(".footer").css({ display: "block" });
    }
  });

  next();
});

export default router;
