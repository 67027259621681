import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import less from 'less'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
// import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css';
import store from './store'
// 大屏响应式
import '@/common/flexible.js';

import '@/style/index.less'
import '@/assets/font/font.css' // 引入字体文件
const app = createApp(App)
var cors = require('cors')
app.use(cors)
app.use(router)
app.use(Antd);
app.use(less)
app.use(store)
// app.use(VueAwesomeSwiper)

app.mount('#app')

