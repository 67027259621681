<template>
  <ContactUs />
  <!-- <HeaderTop :activeIndex="activeIndex" @confirm="confirmNav" /> -->
  <!-- <HeaderTop /> -->
  <!-- <FooterButtom /> -->
  <LoginComponent @close="onCloseLogin" />
  <router-view></router-view>
</template>

<script name="App" setup>
import { computed, ref } from "vue";
import ContactUs from "@/components/ContactUs.vue";
import { useStore } from "vuex";
import LoginComponent from "@/components/LoginComponent";
// import HeaderTop from "@/components/HeaderTop";
const store = useStore();
// const activeIndex = ref(0)
const onCloseLogin = () => {
  store.commit("ifLoginState", false);
};
// const confirmNav= (index)=>{
//   activeIndex.value = index
// }

// const activeIndex = computed(() => store.state.anchor);

// const activeIndex = computed(() => {
//   console.log(123);
//   return localStorage.getItem("anchorIndex");
// });

localStorage.setItem("login", 0);
</script>

<style lang="less">
#app {
  position: relative;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  font-family: "微软雅黑";
}
</style>
