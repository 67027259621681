<template>
  <div class="contact_us_content">
    <a-row class="contact_us_row">
      <a-col :span="14">
        <div class="contact_us_content_left">
          <div>
            <div class="call">请拨打官方热线</div>
            <!-- <div>重庆</div> -->
            <div class="number">86-023-67300477</div>
            <!-- <div>
            或者浏览完整列表，查找 <a style="color: #fff">当地电话号码</a>
          </div> -->
          </div>
        </div>
      </a-col>
      <a-col :span="10" class="contact_us_right_contain">
        <div class="contact_us_content_right">
          <a-row justify="center">
            <a-col :span="4">
              <img src="@/assets/contact.png" height="40" />
            </a-col>
            <a-col :span="16" :offset="1">
              <!-- <div class="wechatShow">
                <div class="contact_oneItem wechat">关注微信公众号</div>
                <div class="contact_onItem_des">
                  关注微信公众号，我们尽快与您联系
                </div>
              </div> -->
              <div class="publicCode">
                信华信重庆企业公众号
                <img
                  src="@/assets/communityCode.png"
                  height="90"
                  class="code"
                />
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="contact_line"></div>
        <div class="contact_us_content_right">
          <a-row justify="center">
            <a-col :span="4">
              <img src="@/assets/chat_right.png" height="40" />
            </a-col>
            <a-col :span="16" :offset="1">
              <div class="contact_oneItem">联系我们</div>
              <div class="contact_onItem_des">
                <!-- 如有任何建议、问题或者反馈，欢迎发送电子邮件给我们。 -->
                123@dhc.com.cn
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="contact_us">
    <div class="cont_top">
      <div>
        <a-popconfirm
          placement="left"
          ok-text="Yes"
          cancel-text="No"
          :showCancel="false"
          trigger="hover"
        >
          <template #title>
            <div class="phone_num" style="font-weight: bold;">0411-85996999</div>
            <div class="phone_des">请拨打官方热线</div>
          </template>
          <div class="con_one">
            <img src="@/assets/index/call.png" />
            <div class="con_des">联系电话</div>
          </div>
        </a-popconfirm>
        <div class="con_line"></div>
        <a-popconfirm
          placement="left"
          ok-text="Yes"
          cancel-text="No"
          :showCancel="false"
          trigger="hover"
        >
          <template #title>
            <div class="phone_num">MC@dhc.com.cn</div>
            <div class="phone_des">请联系我们</div>
          </template>
          <div class="con_one">
            <img src="@/assets/index/mail.png" />
            <div class="con_des">电子邮箱</div>
          </div>
        </a-popconfirm>
        <div class="con_line"></div>
        <a-popconfirm
          placement="left"
          ok-text="Yes"
          cancel-text="No"
          :showCancel="false"
          trigger="hover"
        >
          <template #title>
            <img src="@/assets/index/qr_code.jpg" class="qr_code" />
            <div>扫描二维码关注信华信官方公众号</div>
          </template>
          <div class="con_one">
            <img src="@/assets/index/wx_qr.png" />
            <div class="con_des">微信号</div>
          </div>
        </a-popconfirm>
      </div>
    </div>

    <!-- <div class="to_top" @click="toTop" v-if="ifPC">
      <img src="@/assets/index/to_top.png" />
    </div> -->
  </div>
</template>

<script setup name="ContactUs">
import { computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore()
const ifPC = computed(()=>store.state.ifPC)
const toTop = () => {
  window.scrollTo(0, 0);
};
</script>

<style scoped lang="less">
.contact_us_content {
  width: 44vw;
  position: fixed;
  right: 0;
  top: 34%;
  z-index: 6;
  display: none;
  height: 40vh;
  .contact_us_row {
    height: 100%;
    // overflow: hidden;
  }
  .contact_us_content_left {
    padding: 0 22px;
    text-align: center;
    background: #007db8;
    color: #fff;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .call {
      font-size: 22px;
      margin-bottom: 26px;
    }
    .number {
      font-size: 36px;
      line-height: 38px;
      margin-top: 10px;
      margin-bottom: 40px;
      // margin: 10px 90px 40px ;
      cursor: pointer;
      position: relative;
      &:hover {
        &:before {
          content: "";
          position: absolute;
          height: 2px;
          width: 234px;
          background: #fff;
          bottom: 0;
        }
      }
    }
  }
  .contact_us_right_contain {
    padding: 2vh 0;
    background: #e9ebf1;
    position: relative;
    height: 100%;
    .contact_line {
      width: 100%;
      position: absolute;
      top: 20vh;
      left: 0;
      height: 2px;
      background: gray;
      opacity: 0.1;
    }
    .contact_us_content_right {
      width: 100%;
      height: 18vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .contact_oneItem {
        color: #007db8;
        padding-bottom: 10px;
        cursor: pointer;
        font-size: 16px;
      }
      .contact_onItem_des {
        color: #545454;
        line-height: 14px;
        line-height: 20px;
        font-weight: lighter;
      }
    }
  }
}
.contact_us {
  position: fixed;
  right: 34px;
  top: 45%;
  z-index: 100000;
  @media screen and (max-width: 1000px) {
      top: 60%;
      opacity: 0.8;
    }
  .cont_top {
    width: 98px;
    height: 344px;
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(14, 9, 46, 0.2);
    border-radius: 54px 54px 54px 54px;
    opacity: 1;
    border: 1px solid #deeaff;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1000px) {
      transform: scale(0.7);
    }
    .con_one {
      padding: 24px 0;
      text-align: center;
      cursor: pointer;
      .con_des {
        padding-top: 6px;
      }
    }
    .con_line {
      width: 30px;
      height: 0px;
      opacity: 1;
      border: 1px solid #d8d8d8;
      margin: 0 auto;
    }
  }
  .to_top {
    width: 98px;
    height: 98px;
    background: #ffffff;
    box-shadow: 0px 4px 6px 0px rgba(14, 9, 46, 0.2);
    border-radius: 54px 54px 54px 54px;
    opacity: 1;
    border: 1px solid #deeaff;
    margin-top: 20px;
    text-align: center;
    line-height: 98px;
    cursor: pointer;
  }
}
.publicCode {
  // display: none;
  font-size: 16px;
  color: #007db8;
  padding-bottom: 4px;
  // text-align: center;
}
.code {
  // text-align: center;
  padding-top: 8px;
}
.qr_code {
  height: 200px;
}
.phone_num {
  font-size: 30px;
}
.phone_des {
  text-align: center;
  font-size: 18px;
}
</style>
